import {
  AppearanceOptions,
  CssFontSource,
  CustomFontSource,
} from '@stripe/connect-js';

import StyleVariables from '../styles/base/variables';

interface StripeConfigInterface {
    stripeAppearanceOptions: AppearanceOptions;
    stripeFonts: Array<CssFontSource | CustomFontSource>;
}

const StripeConfig: StripeConfigInterface = {
    stripeAppearanceOptions: {
        variables: {
            fontFamily: '"Poppins", sans-serif',
            colorPrimary: StyleVariables.orangePrimary,
            colorDanger: StyleVariables.errorRedLight,
            borderRadius: StyleVariables.pillButtonBorderRadius,
            colorText: StyleVariables.black,
    
            buttonPrimaryColorBackground: StyleVariables.black,
            buttonPrimaryColorBorder: StyleVariables.black,
            buttonPrimaryColorText: StyleVariables.white,
    
            buttonSecondaryColorBackground: StyleVariables.black,
            buttonSecondaryColorBorder: StyleVariables.black,
            buttonSecondaryColorText: StyleVariables.white,
    
            actionPrimaryColorText: StyleVariables.orangePrimary,
            actionSecondaryColorText: StyleVariables.black,
            badgeNeutralColorBackground: StyleVariables.greyLight,
            badgeNeutralColorBorder: StyleVariables.greyLight,
            badgeNeutralColorText: StyleVariables.black,
    
            badgeSuccessColorBackground: StyleVariables.successGreenLight,
            badgeSuccessColorText: StyleVariables.successGreenDark,
            badgeSuccessColorBorder: StyleVariables.successGreenLight,
    
            badgeWarningColorBackground: StyleVariables.errorRedLight,
            badgeWarningColorText: StyleVariables.errorRedDark,
            badgeWarningColorBorder: StyleVariables.errorRedLight,
    
            formAccentColor: StyleVariables.orangePrimary,
    
            buttonBorderRadius: StyleVariables.pillButtonBorderRadius,
            colorSecondaryText: StyleVariables.greyMid,
            colorBorder: StyleVariables.greyLight,
    
            headingLgFontSize: StyleVariables.largeFontSize,
            headingXlFontSize: StyleVariables.xLargeFontSize,
            bodySmFontSize: StyleVariables.xSmallFontSize,
            bodyMdFontSize: StyleVariables.smallFontSize,
            labelMdFontSize: StyleVariables.smallFontSize,
            labelSmFontSize: StyleVariables.xSmallFontSize,
            // headingMdFontSize: '5px',
            // headingSmFontSize: '',
            // headingXsFontSize: '',
            // spacingUnit: ''
        }
    },
    stripeFonts: [
        { cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:200,300,700&display=swap'}
    ]
};

export default StripeConfig;
