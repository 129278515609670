import { dynamicConfiguration } from './configuration';
import { Currency } from './types';

/**
 * This Constants object should contain any business or product related constants.
 */
class Constants {
    // Application Business/Product constants
    static minimumTransactionAmount: number = 1;
    static maximumTransactionAmount: number = 9999.99;
    static maxPaySelectionButtons: number = 3;
    static omniaFeeMultiplier: number = 0.05;
    static omniaFeeConstant: number = 0;
    static paymentProcessingFeeMultiplier: number = 0.029;
    static paymentProcessingFeeConstant: number = 0.25;
    static otpLength: number = 6;
    static qrCodeVerbs: string[] = ['DONATE', 'SUPPORT', 'TIP', 'NONE'];
    static validCurrencies: Currency[] = Currency.getAllCurrencies();

    // Base URLs
    static frontendURL: string = dynamicConfiguration.frontendURL;
    static backendURL: string = dynamicConfiguration.backendURL;

    // Backend endpoints
    static getProfileDisplayDataEndpoint: string = 'get-profile-display-data';
    static getPaymentIntentEndpoint: string = 'get-stripe-payment-intent';
    static createAccountEndpoint: string = 'create-account';
    static updateProfileEndpoint: string = 'update-profile';
    static confirmEmailAddressEndpoint: string = 'confirm-email-address';
    static authChallengeResponseEndpoint: string = 'auth-challenge-response';
    static initiateAuthRequestEndpoint: string = 'initiate-auth-request';
    static getProfileFromTokenEndpoint: string = 'get-profile-from-token';
    static getAccountStateFromTokenEndpoint: string = 'get-account-state-from-token';
    static createStripeAccountSessionEndpoint: string = 'create-stripe-account-session';
    static sendQRCodeByEmailEndpoint: string = 'send-qr-code-by-email';
    static sendCustomerContactMessageEndpoint: string = 'send-customer-contact-email';

    // Cookie token names
    static jwtTokenName: string = 'omnia-id-token';
    static authSessionIdTokenName: string = 'omnia-auth-session-id';

    // Frontend page paths
    static homePagePath: string = '/';
    static paymentPagePath: string = '/p';
    static paymentConfirmationPagePath: string = '/payment-confirmation';
    static authenticatePagePath: string = '/authenticate';
    static createAccountPagePath: string = '/create-account';
    static connectedAccountPagePath: string = '/connected-account';
    static taxOnTipsPagePath: string = '/tax-on-tips';
    static tippingTipsPagePath: string = '/tipping-tips';
    static aboutPagePath: string = '/about';
    static contactPagePath: string = '/contact';
    static faqsPagePath: string = '/faqs';
    static privacyPolicyPagePath: string = '/privacy-policy';
    static cookiePolicyPagePath: string = '/cookie-policy';
    static acceptableUsePolicyPagePath: string = '/acceptable-use-policy';
    static refundPolicyPagePath: string = '/refund-policy';
    static disclaimerPolicyPagePath: string = '/disclaimer-policy';
    static termsAndConditionsPolicyPagePath: string = '/terms-and-conditions-policy'

    // Registered business strings
    static registeredCompanyName: string = 'Omnia Technology Limited';
    static registeredCompanyAddress: string = '23 West Hill Road, St Leonards-on-Sea, East Sussex, TN38 0NA, United Kingdom';
    static registeredCompanyNumber: string = '12345';

    // Public keys
    static stripePublishableKey: string = dynamicConfiguration.stripePublishableKey;
    static googleReCaptchaSiteKey: string = '6LfjaX4qAAAAAGCExXHhLAoBLGXZ4IYajfwXSkm9';
}

export default Constants;
