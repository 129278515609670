import './dashboardpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  ConnectAccountManagement,
  ConnectComponentsProvider,
  ConnectNotificationBanner,
  ConnectPayments,
  ConnectPayouts,
} from '@stripe/react-connect-js';

import ApiResponse from '../../classes/ApiResponse';
import CreateStripeAccountSessionResponse
  from '../../classes/CreateStripeAccountSessionResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import Constants from '../../configuration/constants';
import StripeConfig from '../../configuration/stripeConfig';

interface DashboardTabItem {
    name: string;
    element: JSX.Element;
  }
  
const tabs: DashboardTabItem[] = [
  {name: 'Payments', element: <ConnectPayments/>},
  {name: 'Payouts', element: <ConnectPayouts/>},
  //{name: 'Documents', element: <ConnectDocuments/>},
  {name: 'Details', element: <ConnectAccountManagement/>}
]

const DashboardPage = (): ReactElement => {
    const navigate = useNavigate();
    
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [stripeConnectInstance] = useState<StripeConnectInstance>(() => {
        const fetchClientSecret = async (): Promise<string> => {
            return await ApiResponse.getApiResponse(
                Constants.createStripeAccountSessionEndpoint,
                null,
                CreateStripeAccountSessionResponse, 
                null,
                null,
                true
            ).then(response => {
                return response ? response.clientSecret : '';
            });
        };

        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };
        
        getAccountStateFromToken().then(response => {
            if (!response) {
                navigate(Constants.authenticatePagePath);
            } else if (response instanceof AxiosError) {
                navigate(Constants.homePagePath);
            } else if (!response.accountCreated ) {
                navigate(Constants.createAccountPagePath);
            } else if (!response.stripeOnboardingCompleted) {
                navigate(Constants.connectedAccountPagePath);
            }
        });
        
        /**
         * Appearance options: https://docs.stripe.com/connect/embedded-appearance-options
         */
        return loadConnectAndInitialize({
            publishableKey: Constants.stripePublishableKey,
            fetchClientSecret: fetchClientSecret,
            fonts: StripeConfig.stripeFonts,
            appearance: StripeConfig.stripeAppearanceOptions
        });
    });
    
    return (
        <>
            <div className='dashboard-navbar'>
                <div className='dashboard-navbar-content'>
                    {tabs.map((item, index) => (
                        <div key={index} className={`dashboard-navbar-button ${selectedTabIndex === index ? 'selected' : ''}`} onClick={() => setSelectedTabIndex(index)}>{item.name}</div>
                    ))}
                </div>
            </div>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <ConnectNotificationBanner/>
                <div className='dashboard-tab-content'>
                    {tabs[selectedTabIndex].element}
                </div>
            </ConnectComponentsProvider>
        </>
    );
}

export default DashboardPage;
