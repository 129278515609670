import './omniamodal.scss';

import {
  ReactElement,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';

import ErrorMessage from './ErrorMessage';
import LoadingIcon from './LoadingIcon';

interface OmniaModalProps {
  modalTitle?: string;
  modalDescription?: string;
  modalBody?: ReactElement;
  actionFunction?: () => void;
  actionVerb?: string;
  actionButtonDisabled?: boolean;
  actionInProgress?: boolean;
  customerErrorMessage?: string;
  onClose: () => void;
  isVisible: boolean;
};

const OmniaModal = (props: OmniaModalProps): ReactElement => {
    const {
        modalTitle,
        modalDescription,
        modalBody,
        actionFunction,
        actionVerb,
        actionButtonDisabled, 
        actionInProgress,
        customerErrorMessage,
        onClose,
        isVisible
    } = props;

    useEffect(() => {
        const htmlElement = document.querySelector('html');
        if (isVisible) {
            htmlElement?.classList.add('no-vertical-scroll');
        } else {
            htmlElement?.classList.remove('no-vertical-scroll');
        }
        return () => {
            htmlElement?.classList.remove('no-vertical-scroll');
        };
    }, [isVisible]);

    if (!isVisible) return <></>;
    
    return ReactDOM.createPortal(
        <div className='modal-overlay' onClick={onClose}>
            <div className='modal' onClick={(e) => e.stopPropagation()}>
                <div className='modal-content'>
                        { modalTitle &&
                            <h1 className='modal-content-title'>{modalTitle}</h1>
                        }
                        { modalDescription && 
                            <p className='modal-content-description'>{modalDescription}</p>
                        }
                        <div className='modal-content-body'>
                            {modalBody}
                        </div>
                        { customerErrorMessage !== '' &&
                            <ErrorMessage errorMessage={customerErrorMessage}/>
                        }
                        { actionFunction && actionVerb &&
                            <div className='modal-content-action-button-section'>
                                { actionInProgress ? 
                                    <LoadingIcon className='loading-icon-wrapper-no-margin-top'/>
                                    :
                                    <div className={'modal-content-action-button' + (actionButtonDisabled ? ' disabled' : '')} onClick={actionFunction}>{actionVerb}</div>
                                }
                            </div>
                        }
                    <div className='modal-close-button' onClick={onClose}>Close</div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default OmniaModal;
