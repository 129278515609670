import './paymentnavbar.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { CiCircleQuestion } from 'react-icons/ci';

import Constants from '../../configuration/constants';
import OmniaModal from '../OmniaModal';
import TextLink from '../TextLink';

const PaymentNavbar = (): ReactElement => {
    const [whatIsOmniaModalVisible, setWhatIsOmniaModalVisible] = useState<boolean>(false);

    const toggleModal = () => {
        setWhatIsOmniaModalVisible(!whatIsOmniaModalVisible);
    }

    return (
        <div className='payment-navbar'>
            <div className='payment-central-navbar'>
                <div className='what-is-omnia-icon-wrapper' onClick={toggleModal}>
                    <CiCircleQuestion className='what-is-omnia-icon'/>
                </div>
                { whatIsOmniaModalVisible &&
                    <OmniaModal
                        modalTitle='What is Omnia?'
                        modalBody={
                            <div>
                                <p className='what-is-omnia-modal-text'>
                                    Omnia is a London based start-up with the goal of revolutionising how poeple receive mobile payments.
                                </p>
                                <p className='what-is-omnia-modal-text'>
                                    We have partnered with the industry-leading payment processor to ensure your transactions are reliable and always secure. Omnia does not see or store <b>any</b> of your financial data.
                                </p>
                                <p className='what-is-omnia-modal-text'>
                                    Want to know more? <TextLink text='Visit Us' href={Constants.frontendURL}/>.
                                </p>
                                <p className='what-is-omnia-modal-text'>
                                    <i>{Constants.registeredCompanyName} is a UK registered business</i>
                                </p>
                            </div>
                          }
                          onClose={toggleModal}
                          isVisible={whatIsOmniaModalVisible}
                    />
                }
                <div className='payment-navbar-logo-wrapper'>
                    <img className='navbar-logo' src={require('../../assets/images/omnia-logos/omnia-logo-black-background.png')} alt='logo'/>
                </div>
            </div>
        </div>
    );
};

export default PaymentNavbar;
