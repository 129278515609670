import HelperMethods from '../helpers/HelperMethods';
import {
  AccountType,
  Address,
  CreateIndividualAccountPageFormData,
} from '../pages/createaccount/types/types';

class CreateIndividualAccountData {
  static type: string = 'CreateAccountData';
  
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  industryType: string;
  accountType: string;
  base64ProfileImage: string;
  currency: string;
  country: string;
  address: Address;
  url: string;
  dob: DoB | null;
  phoneNumber: string;
  
  constructor(data: CreateIndividualAccountPageFormData) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.displayName = data.displayName;
    this.username = data.username.toLowerCase();
    this.industryType = data.industryType;
    this.base64ProfileImage = require('../assets/images/default-profile-image.jpg');
    this.currency = data.currency;
    this.country = data.country;
    this.address = data.address;
    this.url = HelperMethods.getValidUrl(data.url);
    this.dob = data.dob ? this.getDoB(data.dob) : null;
    this.phoneNumber = HelperMethods.getValidPhoneNumber(data.phoneNumber);
    this.accountType = AccountType.INDIVIDUAL.value;
  }

  private getDoB(value: string): DoB {
    const dobArray: string[] = value.split('-');

    return new DoB(parseInt(dobArray[0]), parseInt(dobArray[1]), parseInt(dobArray[2]));
  }
}

class DoB {
  day: number;
  month: number;
  year: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}

export default CreateIndividualAccountData;
