class StyleVariables {
    // Colours
    static orangePrimary = '#ffa500';
    static orangeDark = '#ff8c00';
    static offWhiteBackground = '#f5f5f5';
    static errorRedDark = '#8b0000';
    static errorRedLight = '#ff000025';
    static stripePurplePrimary = '#5433FF';
    static white = '#FFFFFF';
    static black = '#000000'

    static websiteColour = '#0000FF';
    static instagramColour = '#E1306C';
    static facebookColour = '#1877F2';
    static yotubeColour = '#FF0000';
    static spotifyColour = '#1DB954';

    static greyLight = '#d3d3d3';
    static greyMid = '#808080';

    static successGreenLight = '#CEF6BB';
    static successGreenDark = '#05690D';

    // Styles
    static pillButtonBorderRadius = '1000px';
    static roundedButtonBorderWidth = '6px';
    static maxDisplayWidth = '500px';
    static navbarHeight = '70px';

    // Font sizes
    static xSmallFontSize = '12.5px';
    static smallFontSize = '15px';
    static mediumFontSize = '18px';

    static xLargeFontSize = '40px';
    static largeFontSize = '30px';

    // Font Families
    static fontFamily: 'Poppins';
    static boldHeadingFontFamily: 'PoppinsExtraBold';
    static headingFontFamily: 'PoppinsBold';
    static boldPrimaryFontFamily: 'PoppinsBold';
    static primaryFontFamily: 'PoppinsLight';
    static lightPrimaryFontFamily: 'PoppinsExtraLight';

    // Spacing

    // Transition
}

export default StyleVariables;
