import './createaccountpage.scss';
import 'react-phone-input-2/lib/bootstrap.css';

import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { load } from 'recaptcha-v3';

import ApiResponse from '../../classes/ApiResponse';
import CreateAccountRequest from '../../classes/CreateAccountRequest';
import CreateAccountResponse from '../../classes/CreateAccountResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import LoadingIcon from '../../components/LoadingIcon';
import Constants from '../../configuration/constants';
import { Currency } from '../../configuration/types';
import CreateIndividualAccountPage from './CreateIndividualAccountPage';
import {
  AccountType,
  Country,
  CreateAccountPageFormData,
  IndustryType,
} from './types/types';

const submitSection = 'submit-section';

interface CreateAccountPageConfig {
    industryTypes: IndustryType[];
    currencies: Currency[];
    accountTypes: AccountType[];
    countries: Country[]
}

const createAccountPageConfig: CreateAccountPageConfig = {
    industryTypes: IndustryType.getAllIndustryTypes(),
    currencies: Currency.getAllCurrencies(),
    accountTypes: AccountType.getAllAccountTypes(),
    countries: Country.getAllCountries()
}

const CreateAccountPage = (): ReactElement => {
    const form = useForm<CreateAccountPageFormData>({
        defaultValues: {
            accountType: AccountType.INDIVIDUAL.value,
            industryType: IndustryType.BAKERIES.value,
            legalName: '',
            displayName: '',
            username: '',
            phoneNumber: '',
            address: {
                line1: null,
                line2: null,
                city: null,
                state: null,
                postalCode: null,
                country: null
            },
            currency: Currency.GBP.value,
            country: Country.UNITED_KINGDOM.value,
            url: '',
            dob: ''
        },
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });
    const { control, handleSubmit, formState, watch } = form;

    const { errors, isSubmitting } = formState;

    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');
    const [loadingPage, setLoadingPage] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                setCustomerErrorMessage,
                true
            );
        };

        if (loadingPage) {
            getAccountStateFromToken().then(response => {
                if (!response || response instanceof AxiosError) {
                    navigate(Constants.authenticatePagePath);
                } else if (!response || response instanceof AxiosError) {
                    navigate(Constants.homePagePath);
                } else if (response.accountCreated && !response.stripeOnboardingCompleted) {
                    navigate(Constants.connectedAccountPagePath);
                } else if (response.accountCreated && response.stripeOnboardingCompleted) {
                    navigate(Constants.homePagePath);
                }
            });

            setLoadingPage(false);
        }
    }, [loadingPage, navigate]);
    
    const onSubmit = async (formData: CreateAccountPageFormData): Promise<void> => {
        setCustomerErrorMessage('');

        const recaptcha = await load(Constants.googleReCaptchaSiteKey); // Load the reCAPTCHA
        const recaptchaToken = await recaptcha.execute('create_account'); // Execute with an action name

        if (!recaptchaToken) {
            setCustomerErrorMessage('Unable to verify reCAPTCHA. Please refresh the page and try again.');
            return;
        }

        const createAccountRequest = new CreateAccountRequest(formData, recaptchaToken);
        const response: CreateAccountResponse|null = await ApiResponse.getApiResponse(
            Constants.createAccountEndpoint,
            createAccountRequest,
            CreateAccountResponse,
            null,
            setCustomerErrorMessage,
            true);

        if (response && response.accountCreationSuccess) {
            navigate(Constants.connectedAccountPagePath)
        } else if (!response) {
            navigate(Constants.authenticatePagePath);
        }
    }

    if (loadingPage) {
        return <LoadingIcon className='loading-icon-wrapper-large-margin-top'/>;
    }

    return (
        // <>
        //     <DefaultNavbar/>
        //     <PageContent>
        //         <div className='create-profile-page-centre-content'>
        //             <h1 className='create-profile-title-text'>Create Account</h1>
        //                 <form className='form-section' onSubmit={handleSubmit(onSubmit)} noValidate>
        //                     <div className='create-profile-form-card'>
        //                         <h1 className='create-profile-form-card-title'>Business Info</h1>
        //                         <FormInputSelect
        //                             name='businessType'
        //                             label='Business Type'
        //                             control={control}
        //                             helperText='If you have not registered as a business your business type is likely Individual'
        //                             errorText={errors.businessType?.message}
        //                             items={createAccountPageConfig.businessTypes}
        //                         />
        //                         <FormInputSelect
        //                             name='industryType'
        //                             label='Industry Type'
        //                             control={control}
        //                             helperText='Choose the industry which aligns closest to yours'
        //                             errorText={errors.industryType?.message}
        //                             items={createAccountPageConfig.industryTypes}
        //                         />
        //                         <FormInputSelect
        //                             name='currency'
        //                             label='Currency'
        //                             control={control}
        //                             helperText='Choose the currency you want to receive'
        //                             errorText={errors.currency?.message}
        //                             items={createAccountPageConfig.currencies}
        //                         />
        //                         <FormInputText
        //                             name='url'
        //                             label='URL'
        //                             placeholder='www.example.com'
        //                             type='url'
        //                             control={control}
        //                             helperText='Add a URL to your website or a social media profile'
        //                             errorText={errors.url?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'URL is a required field',
        //                                 pattern: {
        //                                     value: /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\.[a-zA-Z0-9-._/?=]{2,})?\/?$/,
        //                                     message: 'Invalid URL',
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         if (fieldValue) {
        //                                             return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
        //                                         } else {
        //                                             return true;
        //                                         }
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputText
        //                             name='username'
        //                             label='Username'
        //                             placeholder='username'
        //                             type='username'
        //                             control={control}
        //                             helperText='This will appear in your pubilc profile URL, once created your username cannot be changed'
        //                             errorText={errors.username?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'Username is a required field',
        //                                     minLength: { value: 3, message: 'Username must be at least 3 characters' },
        //                                     maxLength: { value: 30, message: 'Username cannot exceed 30 characters' },
        //                                     pattern: {
        //                                         value: /^[a-zA-Z0-9_-]+$/, // Letters, numbers, and underscores
        //                                         message: 'Username must only contain letter, number, \'-\' and \'_\' characters'
        //                                     },
        //                                     validate: {
        //                                         leadingOrTrailingSpace: (fieldValue: any) => {
        //                                             if (fieldValue) {
        //                                                 return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
        //                                             } else {
        //                                                 return true;
        //                                             }
        //                                         }
        //                                     }
        //                             }}
        //                         />
        //                     </div>
        //                     <div className='create-profile-form-card'>
        //                         <h1 className='create-profile-form-card-title'>Your Details</h1>
        //                         <FormInputText
        //                             name='displayName'
        //                             label='Display Name'
        //                             placeholder='Display Name'
        //                             type='name'
        //                             control={control}
        //                             helperText='The name to be displayed on your profile e.g. first name, trading name'
        //                             errorText={errors.displayName?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'Display Name is a required field',
        //                                 minLength: { value: 2, message: 'Display Name must be at least 2 characters' },
        //                                 maxLength: { value: 20, message: 'Display Name cannot exceed 20 characters' },
        //                                 pattern: {
        //                                     value: /^[a-zA-Z-. ]+$/,
        //                                     message: 'Display Name must only contain letters, full stops, spaces and hyphens'
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         return fieldValue.trim() === fieldValue || 'Display Name cannot start or end with a space'
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputText
        //                             name='legalName'
        //                             label='Legal Name'
        //                             placeholder='Legal Name'
        //                             type='name'
        //                             control={control}
        //                             helperText='Your first and last name (Individual) or the legal name of your registed business (Company, Non-Profit)'
        //                             errorText={errors.legalName?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'Legal Name is a required field',
        //                                 minLength: { value: 2, message: 'Legal Name must be at least 2 characters' },
        //                                 maxLength: { value: 20, message: 'Legal Name cannot exceed 20 characters' },
        //                                 pattern: {
        //                                     value: /^[a-zA-Z-. ]+$/,
        //                                     message: 'Legal Name must only contain letters, full stops, spaces and hyphens'
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         return fieldValue.trim() === fieldValue || 'Legal Name cannot start or end with a space'
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputSelect
        //                             name='country'
        //                             label='Country'
        //                             control={control}
        //                             helperText='The country in which you are active (Individual) or in which your business is legally established (Company, Non-profit)'
        //                             errorText={errors.country?.message}
        //                             items={createAccountPageConfig.countries}
        //                         />
        //                         <FormInputDate
        //                             name='dob'
        //                             label='Date of Birth'
        //                             control={control}
        //                             helperText='Enter your date of birth'
        //                             errorText={errors.dob?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'Date of Birth is a required field',
        //                                 pattern: {
        //                                     value: /^(?:\d{4})-(?:\d{2})-(?:\d{2})$/,  // Matches 'YYYY-MM-DD' format
        //                                     message: 'Date of Birth format is invalid'
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputPhone
        //                             name='phoneNumber'
        //                             label='Phone Number'
        //                             control={control}
        //                             helperText='Your personal (Individual) or business (Company, Non-Profit) phone nunmber'
        //                             errorText={errors.phoneNumber?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'Phone Number is a required field',
        //                                 pattern: {
        //                                     value: /^\+?[0-9]+$/,
        //                                     message: 'Phone Number format is invalid'
        //                                 }
        //                             }}
        //                         />
        //                     </div>
        //                     <div className='create-profile-form-card'>
        //                         <h1 className='create-profile-form-card-title'>Address</h1>
        //                         <FormInputText
        //                             name='address.line1'
        //                             label='Line 1'
        //                             placeholder='Line 1'
        //                             type='address'
        //                             control={control}
        //                             helperText='The first line or your address'
        //                             errorText={errors.address?.line1?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'Address Line 1 is a required field',
        //                                 pattern: {
        //                                     value: /^[a-zA-Z0-9., -']+$/,
        //                                     message: 'Adddress Line 1 must only contain letter, number, space, \' . \', \' , \', \' \' \', and \' - \' characters',
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         if (fieldValue) {
        //                                             return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
        //                                         } else {
        //                                             return true;
        //                                         }
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputText
        //                             name='address.line2'
        //                             label='Line 2'
        //                             placeholder='Line 2'
        //                             type='address'
        //                             control={control}
        //                             helperText='The second line of your address'
        //                             errorText={errors.address?.line2?.message}
        //                             required={false}
        //                             rules={{
        //                                 pattern: {
        //                                     value: /^[a-zA-Z0-9., -']+$/,
        //                                     message: 'Adddress Line 2 must only contain letter, number, space, \' . \', \' , \', \' \' \', and \' - \' characters',
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         if (fieldValue) {
        //                                             return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
        //                                         } else {
        //                                             return true;
        //                                         }
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputText
        //                             name='address.city'
        //                             label='City'
        //                             placeholder='City'
        //                             type='address'
        //                             control={control}
        //                             helperText='Your City or Town'
        //                             errorText={errors.address?.city?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'City is a required field',
        //                                 pattern: {
        //                                     value: /^[a-zA-Z0-9., -']+$/,
        //                                     message: 'City must only contain letter, number, space, \' . \', \' , \', \' \' \', and \' - \' characters',
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         if (fieldValue) {
        //                                             return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
        //                                         } else {
        //                                             return true;
        //                                         }
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputText
        //                             name='address.state'
        //                             label='State'
        //                             placeholder='State'
        //                             type='address'
        //                             control={control}
        //                             helperText='Your State or County'
        //                             errorText={errors.address?.state?.message}
        //                             required={false}
        //                             rules={{
        //                                 pattern: {
        //                                     value: /^[a-zA-Z0-9., -']+$/,
        //                                     message: 'State must only contain letter, number, space, \' . \', \' , \', \' \' \', and \' - \' characters',
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         if (fieldValue) {
        //                                             return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
        //                                         } else {
        //                                             return true;
        //                                         }
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                         <FormInputText
        //                             name='address.postalCode'
        //                             label='Postal Code'
        //                             placeholder='Postal Code'
        //                             type='address'
        //                             control={control}
        //                             helperText='Your Postal or ZIP code'
        //                             errorText={errors.address?.postalCode?.message}
        //                             required={true}
        //                             rules={{
        //                                 required: 'Postal Code is a required field',
        //                                 pattern: {
        //                                     value: /^[a-zA-Z0-9., -']+$/,
        //                                     message: 'Postal Code must only contain letter, number, space, \' . \', \' , \', \' \' \', and \' - \' characters',
        //                                 },
        //                                 validate: {
        //                                     leadingOrTrailingSpace: (fieldValue: string) => {
        //                                         if (fieldValue) {
        //                                             return fieldValue.trim() === fieldValue || 'Fields cannot start or end with a space'
        //                                         } else {
        //                                             return true;
        //                                         }
        //                                     }
        //                                 }
        //                             }}
        //                         />
        //                     </div>
        //                     { customerErrorMessage !== '' &&
        //                         <ErrorMessage errorMessage={customerErrorMessage}/>
        //                     }
        //                     <div className='create-account-page-terms'>
        //                         <p>By creating an account you are acknowledging you have read and agree to our <TextLink text='Terms and Conditions' href={`${Constants.frontendURL}${Constants.termsAndConditionsPolicyPagePath}`} colour='grey-mid'/>, <TextLink text='Privacy Policy' href={`${Constants.frontendURL}${Constants.privacyPolicyPagePath}`} colour='grey-mid'/> and <TextLink text='Cookie Policy' href={`${Constants.frontendURL}${Constants.cookiePolicyPagePath}`} colour='grey-mid'/>.</p>
        //                     </div>
        //                     <div className={submitSection}>
        //                         { !isSubmitting ?
        //                             <button className='create-account-submit-form-button' disabled={isSubmitting}>Create Account</button>
        //                             :
        //                             <div><Puff stroke={StyleVariables.orangePrimary}/></div>
        //                         }
        //                     </div>
        //                 </form>
        //                 {/* <DevTool control={control}/> */}
        //         </div>
        //     </PageContent>
        // </>
        <CreateIndividualAccountPage/>
    );
}

export default CreateAccountPage;
