import './taxontipspage.scss';

import { ReactElement } from 'react';

import PageContent from '../../../components/PageContent';
import TextLink from '../../../components/TextLink';

const TaxOnTipsPage = (): ReactElement => {
    return (
        <PageContent alignItems='left'>
            <h1>Tax on Tips</h1>
            <img className='tax-on-tips-image' src={require('../../../assets/images/websitecontent/tax-calculator.jpg')}/>
            <p>Omnia has been created as a cash-equivalent system. This means the money you receive through Omnia goes straight from the conferrer to you, exactly the same as with cash.  We want to make sure that all of our users know that the money you receive through Omnia is yours, and yours alone (just like cash). Therefore it’s up to you to declare payments for tax purposes.</p>  
            <p>We do understand that some people can find handling tax worrying or stressful, but this shouldn’t be the case! Understanding how to correctly declare your earnings can empower you, by ensuring you know exactly where the tax you pay on your hard-earned money is going – towards helping the society you are a part of!</p>
            <p>Remember, you are allowed to earn up to a certain amount without paying tax – so if you’ve earned less than that overall you won’t have to pay any tax at all.  After that you’ll pay tax based on what you earn, and how much you pay will depend on where you live in the UK. See <TextLink href='https://www.gov.uk/income-tax-rates' text='here'/> for more details.</p>
        </PageContent>
    );
}

export default TaxOnTipsPage;
