import './connectedaccountpage.scss';

import {
  ReactElement,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { FaStripe } from 'react-icons/fa';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  CollectionOptions,
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';

import ApiResponse from '../../classes/ApiResponse';
import CreateStripeAccountSessionResponse
  from '../../classes/CreateStripeAccountSessionResponse';
import GetAccountStateFromTokenResponse
  from '../../classes/GetAccountStateFromTokenResponse';
import DefaultNavbar from '../../components/navbar/DefaultNavbar';
import PageContent from '../../components/PageContent';
import Constants from '../../configuration/constants';
import StripeConfig from '../../configuration/stripeConfig';

const ConnectedAccountPage = (): ReactElement => {
    const [showStripeOnboardingElement, setShowStripeOnboardingElement] = useState<boolean>(false);
    const [stripeConnectInstance] = useState<StripeConnectInstance>(() => {
        const fetchClientSecret = async (): Promise<string> => {
            return await ApiResponse.getApiResponse(
                Constants.createStripeAccountSessionEndpoint,
                null,
                CreateStripeAccountSessionResponse, 
                null,
                null,
                true
            ).then(response => {
                return response ? response.clientSecret : '';
            });
        };

        const getAccountStateFromToken = async (): Promise<GetAccountStateFromTokenResponse | null> => {
            return await ApiResponse.getApiResponse(
                Constants.getAccountStateFromTokenEndpoint,
                null,
                GetAccountStateFromTokenResponse,
                null,
                null,
                true
            );
        };
        
        getAccountStateFromToken().then(response => {
            if (!response || response instanceof AxiosError) {
                navigate(Constants.authenticatePagePath);
            } else if (!response || response instanceof AxiosError) {
                navigate(Constants.homePagePath);
            } else if (!response.accountCreated) {
                navigate(Constants.createAccountPagePath);
            } else if (response.stripeOnboardingCompleted) {
                navigate(Constants.homePagePath);
            }
        });
        
        /**
         * Appearance options: https://docs.stripe.com/connect/embedded-appearance-options
         */
        return loadConnectAndInitialize({
            publishableKey: Constants.stripePublishableKey,
            fetchClientSecret: fetchClientSecret,
            fonts: StripeConfig.stripeFonts,
            appearance: StripeConfig.stripeAppearanceOptions
        });
    });

    const navigate = useNavigate();

    const collectionOptions: CollectionOptions = {
        fields: 'eventually_due',
        futureRequirements: 'include'
    };
    
    return (
        <>
            <DefaultNavbar/>
            <PageContent>
                { showStripeOnboardingElement ? 
                    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                        <ConnectAccountOnboarding
                            onExit={() => {
                                navigate(`${Constants.frontendURL}${Constants.homePagePath}`);
                            }}
                            collectionOptions={collectionOptions}
                            // Optional: make sure to follow our policy instructions above
                            // fullTermsOfServiceUrl="{{URL}}"
                            // recipientTermsOfServiceUrl="{{URL}}"
                            // privacyPolicyUrl="{{URL}}"
                            // skipTermsOfServiceCollection={false}
                            // collectionOptions={{
                            //   fields: 'eventually_due',
                            //   futureRequirements: 'include',
                            // }}
                        />                   
                    </ConnectComponentsProvider>
                    : 
                    <div className='connected-account-page-centre-content'>
                        <div className='connect-to-stripe-icon-wrapper'><FaStripe className='connect-to-stripe-icon'/></div>
                        <div className='connect-to-stripe-title-wrapper'><h1 className='connect-to-stripe-title'>Connect your account with Stripe</h1></div>
                        <div className='connect-to-stripe-text'>We've partnered with Stripe to make sure you get paid on time and to keep your personal and bank details secure.</div>
                        <FaCircleArrowRight className='connect-to-stripe-next-arrow' onClick={() => setShowStripeOnboardingElement(true)}/>
                    </div>
                }               
            </PageContent>
        </>
    );
}

export default ConnectedAccountPage;
