import { ReactElement } from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import OmniaDailyRevenue from '../../classes/OmniaDailyRevenue';
import HelperMethods from '../../helpers/HelperMethods';
import StyleVariables from '../../styles/base/variables';

const formatDateFromTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
  return date.toLocaleDateString(undefined, options);
};

const generateXAxisTicks = (minTimestamp: number, maxTimestamp: number): number[] => {
  const oneDay = 24 * 60 * 60; // Seconds in a day
  const ticks: number[] = [];

  // Start from the first full
  let currentTick = Math.floor(minTimestamp / oneDay) * oneDay; // Start of the first full day
  
  // Generate ticks for the start of every alternate day between minTimestamp and maxTimestamp
  while (currentTick <= maxTimestamp) {
    ticks.push(currentTick);
    currentTick += oneDay*2;
  }

  return ticks;
};

const CustomTooltip = ({ payload, label, currency }: any) => {
  if (payload && payload.length) {
      const { value } = payload[0];
      return (
          <div className='custom-tooltip'>
              <p>{`Date: ${formatDateFromTimestamp(label)}`}</p>
              <p>{`Amount: ${HelperMethods.getCurrencySymbolFromString(currency)}${value.toFixed(2)}`}</p>
          </div>
      );
  }
  return null;
};

const calculateYAxisMargin = (maxAmount: number): number => {
  if (maxAmount > 8000) {
    return 40;
  } else if (maxAmount > 800) {
    return 30;
  } else if (maxAmount > 80) {
    return 25;
  } else {
    return 15;
  }
}

interface DailyPaymentsBarChart {
  dailyRevenueData: OmniaDailyRevenue[];
}

export const DailyPaymentsBarChart = (props: DailyPaymentsBarChart): ReactElement => {
  const { dailyRevenueData } = props;

  const maxAmount = Math.max(...dailyRevenueData.map(data => data.amount));
  const yAxisPadding = calculateYAxisMargin(maxAmount);
  
  return (
    <ResponsiveContainer width='100%' height={300}>
      <BarChart
        data={dailyRevenueData}
      >
        <CartesianGrid strokeDasharray='3 3' vertical={false}/>
        <XAxis
          dataKey='date'
          //ticks={generateXAxisTicks(minTimestamp, maxTimestamp)}
          tickFormatter={formatDateFromTimestamp}
          tick={{ fontSize: 10, fill: 'black' }}
        />
        <YAxis
          width={yAxisPadding}
          dataKey='amount'
          tick={{ fontSize: 10, fill: 'black' }}
        />
        <Tooltip content={<CustomTooltip currency={dailyRevenueData[0].currency}/>} />
        <Bar
          dataKey='amount'
          fill={StyleVariables.orangePrimary}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
