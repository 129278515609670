import {
  AccountType,
  CreateIndividualAccountPageFormData,
} from '../pages/createaccount/types/types';
import CreateIndividualAccountData from './CreateIndividualAccountData';

class CreateIndividualAccountRequest {
  static type: string = 'CreateAccountRequest';
  
  accountCreateData: CreateIndividualAccountData;
  accountType: string;
  recaptchaToken: string|null;
    
  constructor(data: CreateIndividualAccountPageFormData, recaptchaToken: string|null) {
    this.accountCreateData = new CreateIndividualAccountData(data);
    this.accountType = AccountType.INDIVIDUAL.value;
    this.recaptchaToken = recaptchaToken;
  }
}

export default CreateIndividualAccountRequest;
