import HelperMethods from '../helpers/HelperMethods';
import { SocialMediaLinks } from '../types/types';
import UserProfileData from './UserProfileData';

class UpdateProfileRequest {
    static type: string = 'UpdateProfileRequest';
    
    displayName: string;
    base64ProfileImage: string;
    suggestedPayValues: number[];
    thankyouMessage: string;
    socialMediaLinks: SocialMediaLinks;
    currency: string;
    qrCodeVerb: string;
      
    constructor(data: UserProfileData) {
        this.displayName = data.displayName;
        this.base64ProfileImage = data.base64ProfileImage;
        this.suggestedPayValues = HelperMethods.getValidButtonValues(data.suggestedPayValues);
        this.thankyouMessage = data.thankyouMessage;
        this.socialMediaLinks = HelperMethods.getValidSocialMediaLinks(data.socialMediaLinks);
        this.currency = data.currency;
        this.qrCodeVerb = data.qrCodeVerb;
    }
}

export default UpdateProfileRequest;
