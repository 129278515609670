import HelperMethods from '../helpers/HelperMethods';
import { SocialMediaLinks } from '../types/types';
import OmniaAccountBalance from './OmniaAccountBalance';
import OmniaDailyRevenuesForCurrency from './OmniaDailyRevenueForCurrency';
import TransactionTotal from './TransactionTotal';

class UserProfileData {
    static type: string = 'UserProfileData';
    
    username: string;
    displayName: string;
    stripeConnectedAccountId: string;
    base64ProfileImage: string;
    suggestedPayValues: number[];
    thankyouMessage: string;
    socialMediaLinks: SocialMediaLinks;
    currency: string;
    qrCodeVerb: string;
    receivedAmountPerCurrency: TransactionTotal[];
    accountBalancePerCurrency: OmniaAccountBalance[];
    dailyRevenuesPerCurrencyData: OmniaDailyRevenuesForCurrency[];
  
    constructor(data: UserProfileData) {
        this.username = data.username;
        this.displayName = data.displayName;
        this.stripeConnectedAccountId = data.stripeConnectedAccountId;
        this.base64ProfileImage = data.base64ProfileImage;
        this.suggestedPayValues = HelperMethods.getValidButtonValues(data.suggestedPayValues);
        this.thankyouMessage = data.thankyouMessage;
        this.socialMediaLinks = HelperMethods.getValidSocialMediaLinks(data.socialMediaLinks);
        this.currency = data.currency;
        this.qrCodeVerb = data.qrCodeVerb;
        this.receivedAmountPerCurrency = data.receivedAmountPerCurrency;
        this.accountBalancePerCurrency = data.accountBalancePerCurrency;
        this.dailyRevenuesPerCurrencyData = data.dailyRevenuesPerCurrencyData;
    }
}

export default UserProfileData;
