import './paymentarea.scss';

import {
  FormEvent,
  ReactElement,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import {
  FaCircleChevronDown,
  FaCircleChevronLeft,
} from 'react-icons/fa6';

import {
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js';

import ApiResponse from '../../classes/ApiResponse';
import GetPaymentIntentRequest from '../../classes/GetPaymentIntentRequest';
import GetPaymentIntentResponse from '../../classes/GetPaymentIntentResponse';
import LoadingIcon from '../../components/LoadingIcon';
import Constants from '../../configuration/constants';
import Strings from '../../configuration/strings';
import HelperMethods from '../../helpers/HelperMethods';

interface PaymentElementProps {
    getPaymentIntentRequest: GetPaymentIntentRequest;
    onErrorMessage: (message: string) => void;
};

const PaymentArea = (props: PaymentElementProps): ReactElement => {
    const { getPaymentIntentRequest, onErrorMessage } = props;

    const stripe: Stripe|null = useStripe();
    const elements: StripeElements|null = useElements();

    const [paymentElementVisible, setPaymentElementVisible] = useState(false); // State to manage visibility of PaymentElement
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission status

    /**
    * Documentation for StripeExpressCheckoutElementOptions: https://stripe.com/docs/js/element/express_checkout_element
    */
    const stripeExpressCheckoutOptions: StripeExpressCheckoutElementOptions = {
        // buttonType: {
        //     applePay: 'plain',
        //     googlePay: 'plain'
        // }
    };

    /**
     * Configures the payment interface display.
     * Documentation: https://stripe.com/docs/js/element/events/on_click?type=expressCheckoutElement
     */
    const expressCheckoutOnClick = (event: StripeExpressCheckoutElementClickEvent): void => {
        if (getPaymentIntentRequest.transactionAmount < Constants.minimumTransactionAmount) {
            onErrorMessage(`Your chosen amount is too small. It must be at least ${HelperMethods.getCurrencySymbolFromString(getPaymentIntentRequest.currency)}${Constants.minimumTransactionAmount.toFixed(2)}.`);
            return;
        } else {
            onErrorMessage('');
        }

        event.resolve({business: {name: getPaymentIntentRequest.displayName}})
    }

    /**
     * Creates a Payment Method which is sent to server for validation.
     * Documentation: https://stripe.com/docs/elements/express-checkout-element/accept-a-payment?client=react#create-pm
     * Destination Charges Payment Intent: https://docs.stripe.com/connect/destination-charges?platform=web&ui=elements#create-payment-intent
     */
    const expressCheckoutOnConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
        try {
            if (!stripe || !elements) {
                event.paymentFailed();
                onErrorMessage(Strings.defaultCustomerErrorMessage);
                return;
            }
            
            const { error: elementsSubmitError } = await elements.submit();
            if (elementsSubmitError) {
                event.paymentFailed();
                onErrorMessage(elementsSubmitError.message ? elementsSubmitError.message : 'Error');
                return;
            }

            const response: GetPaymentIntentResponse | null = await ApiResponse.getApiResponse(
                Constants.getPaymentIntentEndpoint,
                getPaymentIntentRequest,
                GetPaymentIntentResponse,
                null,
                onErrorMessage,
                false);

            if (!response || response instanceof AxiosError) {
                onErrorMessage(Strings.defaultCustomerErrorMessage);
                event.paymentFailed();
                return;
            }
            
            const { clientSecret } = response;
            
            const { error: confirmPaymentError } = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${Constants.frontendURL}${Constants.paymentConfirmationPagePath}/${getPaymentIntentRequest.username}`,
                }
            });
            
            if (confirmPaymentError) {
                onErrorMessage(Strings.defaultCustomerErrorMessage);
                event.paymentFailed();
                return;
            }
        } catch (e) {
            const err = e as AxiosError;
            onErrorMessage(`catch error: ${err.cause} | ${err.code} | ${err.config} | ${err.message} | ${err.name} | ${err.request} | ${err.response} | ${err.toJSON}`);
            event.paymentFailed();
            return;
        }
    }

    const handlePaymentElementSubmit = async (event: FormEvent) => {
        event.preventDefault(); // Prevent the default form submission

        if (!stripe || !elements) {
            onErrorMessage(Strings.defaultCustomerErrorMessage);
            return;
        }

        setIsSubmitting(true); // Set submitting state to true

        const { error: elementsSubmitError } = await elements.submit();
        if (elementsSubmitError) {
            setIsSubmitting(false); // Reset submitting state
            onErrorMessage(elementsSubmitError.message ? elementsSubmitError.message : 'Error');
            return;
        }

        // Call your API to get the payment intent
        const response: GetPaymentIntentResponse | null = await ApiResponse.getApiResponse(
            Constants.getPaymentIntentEndpoint,
            getPaymentIntentRequest,
            GetPaymentIntentResponse,
            null,
            onErrorMessage,
            false
        );

        if (!response || response instanceof AxiosError) {
            setIsSubmitting(false); // Reset submitting state
            onErrorMessage(Strings.defaultCustomerErrorMessage);
            return;
        }

        const { clientSecret } = response;

        // Confirm the payment
        const { error: confirmPaymentError } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: `${Constants.frontendURL}/payment-confirmation/${getPaymentIntentRequest.username}`,
            }
        });

        if (confirmPaymentError) {
            setIsSubmitting(false); // Reset submitting state
            onErrorMessage(Strings.defaultCustomerErrorMessage);
            return;
        }

        // Payment successful, handle success here
        setIsSubmitting(false); // Reset submitting state
    }
  
    return (
        <div className='payment-area'>
            <ExpressCheckoutElement options={stripeExpressCheckoutOptions} onClick={expressCheckoutOnClick} onConfirm={expressCheckoutOnConfirm}/>
            <p>or</p>
            <div className='enter-bank-details-section'>
                <div className='enter-bank-details-section-heading' onClick={() => setPaymentElementVisible(!paymentElementVisible)}>
                    <p>Enter bank details manually</p>
                    { paymentElementVisible ? <FaCircleChevronDown className='enter-bank-details-chevron'/> : <FaCircleChevronLeft className='enter-bank-details-chevron'/>}
                </div>
                { paymentElementVisible &&
                    <form onSubmit={handlePaymentElementSubmit}>
                        <PaymentElement />
                        <div className='payment-element-submit-section'>
                            { isSubmitting ?
                                <LoadingIcon className='loading-icon-wrapper-no-margin-top'/>
                                :
                                <div
                                    className='payment-element-submit-button'
                                    onClick={(e) => {
                                        e.preventDefault(); // Prevent the default form submission
                                        handlePaymentElementSubmit(e); // Call the form submission handler
                                    }}
                                >Pay</div>  
                            }
                        </div>
                    </form>
                }
            </div>
        </div>
    );
}

export default PaymentArea;
