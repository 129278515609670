import {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import ApiResponse from '../../../classes/ApiResponse';
import UpdateProfileRequest from '../../../classes/UpdateProfileRequest';
import UpdateProfileResponse from '../../../classes/UpdateProfileResponse';
import UserProfileData from '../../../classes/UserProfileData';
import OmniaModal from '../../../components/OmniaModal';
import Constants from '../../../configuration/constants';
import HelperMethods from '../../../helpers/HelperMethods';
import { updateUserProfileData } from '../../../redux/userProfileDataSlice';
import Base64ProfileImageEdit from './Base64ProfileImageEdit';
import DisplayNameEdit from './DisplayNameEdit';
import QRCodeVerbEdit from './QRCodeVerbEdit';
import SocialMediaLinksEdit from './SocialMediaLinksEdit';
import SuggestedPayValuesEdit from './SuggestedPayValuesEdit';
import ThankyouMessageEdit from './ThankyouMessageEdit';

export type ProfileAttribute = 'suggestedPayValues' 
    | 'displayName'
    | 'base64ProfileImage'
    | 'thankyouMessage'
    | 'socialMediaLinks'
    | 'qrCodeVerb';

interface EditProfileModalProps {
    userProfileData: UserProfileData;
    profileAttribute: ProfileAttribute;
    onClose: () => void;
    isVisible: boolean;
}

const EditProfileModal = (props: EditProfileModalProps): ReactElement => {
    const {
        userProfileData,
        profileAttribute, 
        onClose,
        isVisible
    } = props;

    const [customerErrorMessage, setCustomerErrorMessage] = useState<string>('');
    const [updatingProfile, setUpdatingProfile] = useState<boolean>(false);
    const [updatedUserProfileData, setUpdatedUserProfileData] = useState<UserProfileData>(HelperMethods.cloneWithUpdates(UserProfileData, userProfileData, {}));
    const [hasMadeEdit, setHasMadeEdit] = useState<boolean>(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        // Updates state to default state if the user closes the modal
        if (!isVisible) {
            setUpdatedUserProfileData(userProfileData);
            setHasMadeEdit(false);
            setCustomerErrorMessage('');
            setUpdatingProfile(false);
        }
    }, [isVisible, userProfileData]);

    const saveChanges = async (): Promise<void> => {
        setCustomerErrorMessage('');
        setUpdatingProfile(true);

        await ApiResponse.getApiResponse(
            Constants.updateProfileEndpoint,
            new UpdateProfileRequest(updatedUserProfileData),
            UpdateProfileResponse,
            null,
            null,
            true
        ).then(response => {
            JSON.stringify(response);
            setUpdatingProfile(false);
            if (!response || (response instanceof AxiosError && response.request.status === 401)) {
                navigate(Constants.authenticatePagePath);
            } else if (response instanceof AxiosError) {
                setCustomerErrorMessage(response.response?.data);
            } else if (response instanceof AxiosError || !response.profileUpdateSuccess) {
                navigate(Constants.homePagePath);
            } else {
                // Using Redux Dispatch to update the userProfileData in the Redux store
                dispatch(updateUserProfileData(updatedUserProfileData))
                onClose();
            }
        });
    }

    const renderContent = () => {
        switch (profileAttribute) {
            case 'suggestedPayValues':
                return <OmniaModal
                    modalTitle='Suggested Values'
                    modalDescription={`Add up to ${Constants.maxPaySelectionButtons} suggested payment amounts (min ${HelperMethods.getCurrencySymbolFromString(userProfileData.currency)}${Constants.minimumTransactionAmount.toFixed(2)}) to appear on your profile`}
                    modalBody={
                        <SuggestedPayValuesEdit
                            updatedUserProfileData={updatedUserProfileData}
                            setUpdatedUserProfileData={setUpdatedUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                    actionFunction={saveChanges}
                    actionVerb='Save'
                    actionButtonDisabled={!hasMadeEdit}
                    actionInProgress={updatingProfile}
                    customerErrorMessage={customerErrorMessage}
                    onClose={onClose}
                    isVisible={isVisible}
                />;
            case 'base64ProfileImage':
                return <OmniaModal
                    modalTitle='Profile Image'
                    modalDescription='Upload a photo or logo to be displayed on your profile'
                    modalBody={
                        <Base64ProfileImageEdit
                            updatedUserProfileData={updatedUserProfileData}
                            setUpdatedUserProfileData={setUpdatedUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                    actionFunction={saveChanges}
                    actionVerb='Save'
                    actionButtonDisabled={!hasMadeEdit}
                    actionInProgress={updatingProfile}
                    customerErrorMessage={customerErrorMessage}
                    onClose={onClose}
                    isVisible={isVisible}
                />;
            case 'displayName':
                return <OmniaModal   
                    modalTitle='Display Name'
                    modalDescription='Your first name, stage name or the operating name of your business to be displayed on your profile'
                    modalBody={
                        <DisplayNameEdit
                            updatedUserProfileData={updatedUserProfileData}
                            setUpdatedUserProfileData={setUpdatedUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                    actionFunction={saveChanges}
                    actionVerb='Save'
                    actionButtonDisabled={!hasMadeEdit}
                    actionInProgress={updatingProfile}
                    customerErrorMessage={customerErrorMessage}
                    onClose={onClose}
                    isVisible={isVisible}
                />;
            case 'thankyouMessage':
                return <OmniaModal
                    modalTitle='Personal Message'
                    modalDescription='A chance to thank those who support you'
                    modalBody={
                        <ThankyouMessageEdit
                            updatedUserProfileData={updatedUserProfileData}
                            setUpdatedUserProfileData={setUpdatedUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                    actionFunction={saveChanges}
                    actionVerb='Save'
                    actionButtonDisabled={!hasMadeEdit}
                    actionInProgress={updatingProfile}
                    customerErrorMessage={customerErrorMessage}
                    onClose={onClose}
                    isVisible={isVisible}
                />;
            case 'socialMediaLinks':
                return <OmniaModal
                    modalTitle='Social Media'
                    modalDescription='Increase your following by sharing your social media links'
                    modalBody={
                        <SocialMediaLinksEdit
                            updatedUserProfileData={updatedUserProfileData}
                            setUpdatedUserProfileData={setUpdatedUserProfileData}
                            setHasMadeEdit={setHasMadeEdit}
                        />
                    }
                    actionFunction={saveChanges}
                    actionVerb='Save'
                    actionButtonDisabled={!hasMadeEdit}
                    actionInProgress={updatingProfile}
                    customerErrorMessage={customerErrorMessage}
                    onClose={onClose}
                    isVisible={isVisible}
                />;
                case 'qrCodeVerb':
                    return <OmniaModal
                        modalTitle='QR Code Verb'
                        modalDescription='Adding an action verb can increase how many people interract with your QR Code'
                        modalBody={
                            <QRCodeVerbEdit
                                updatedUserProfileData={updatedUserProfileData}
                                setUpdatedUserProfileData={setUpdatedUserProfileData}
                                setHasMadeEdit={setHasMadeEdit}
                            />
                        }
                        actionFunction={saveChanges}
                        actionVerb='Save'
                        actionButtonDisabled={!hasMadeEdit}
                        actionInProgress={updatingProfile}
                        customerErrorMessage={customerErrorMessage}
                        onClose={onClose}
                        isVisible={isVisible}
                    />;
            default:
                return <></>;
        }
    };

    return (
        renderContent()
    );
}

export default EditProfileModal;
